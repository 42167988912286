@import "typo";
@import "color";
@import "utils";

.box-stacks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  padding: 16px;
  box-shadow: 0 0 0 1px $ss-gray-30 inset;
  border-radius: 4px;
}

.box-stacks-cutoff {
  @extend .box-stacks;
  gap: 8px;
  padding: 16px;
  box-shadow: none;
  border: 1px solid $ss-gray-30;
  border-radius: 8px;

  .cutoffed-num {
    @include caption-text-1;
    color: $ss-gray-60;
  }
}

.box-stacks-detail {
  padding: 26px 20px 24px 20px;
  background-color: $ss-gray-10;

  .stacks-required {
    .title {
      .content-with-icon {
        margin-left: 8.6px;
      }
    }

    .stacks-container {
      display: flex;
      flex-wrap: wrap;
      margin: 18px 0 34px 0;
      gap: 8px;
    }
  }

  .stacks-preferred {
    .stacks-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 18px;
      gap: 8px;
    }
  }

  .title {
    @extend .flex-box;
    @include body-text-2;

    .content-with-icon {
      margin-left: 8px;
    }
  }
}

.stack-item-normal {
  @include caption-text-1;
  display: flex;
  align-items: center;
  color: $ss-gray-90;

  .content-with-icon {
    margin-left: 4px;
  }
}

.stack-item-detail--qualified {
  @extend .stack-item-detail;

  .status {
    background: $ss-green-5;
    color: $ss-green-50;
    box-shadow: inset 1px 0 0 $ss-green-20, inset -1px 1px 0 $ss-green-20;

    .content-with-icon {
      margin-left: 5px;
    }
  }
}

.stack-item-detail--unqualified {
  @extend .stack-item-detail;

  .status {
    color: $ss-gray-60;
    background-color: $ss-gray-10;
    box-shadow: inset 1px 0 0 $ss-gray-30, inset -1px 1px 0 $ss-gray-30;

    .content-with-icon {
      margin-left: 6.5px;
    }
  }

  .stack-img {
    opacity: 0.4;
  }

  .content {
    opacity: 0.4;
  }
}

.stack-item-detail {
  min-width: 120px;
  height: 168px;
  text-align: center;
  background-color: $ss-white;
  box-shadow: 0 0 0 1px $ss-gray-20 inset;
  border-radius: 4px;

  .status {
    @extend .flex-box;
    @include caption-text-1;
    justify-content: center;
    padding: 10px 0;
  }

  .stack-img {
    margin-top: 12px;
  }

  .content {
    .name {
      @include body-text-2;
    }

    .year {
      @include caption-text-1;
      color: $ss-gray-70;
    }
  }
}

.stack-item-simple {
  padding: 8px 12px 8px 8px;
  background: $ss-white;
  box-shadow: inset 0 0 0 1px $ss-gray-30;
  border-radius: 4px;
  cursor: default;

  .content-box {
    @extend .flex-box;
    @include body-text-3;

    .content-with-img {
      margin-left: 4px;
      display: flex;
    }
    .stack-item-X {
      fill: $ss-gray-80;
      margin: 2px 0 0 6px;

    }
    .clickable {
      cursor: pointer;
    }
    &.clickable {
      cursor: pointer;
    }
  }
}

.stack-item-simple--selected {
  @extend .stack-item-simple;
  box-shadow: inset 0 0 0 1px $ss-green-50;
}

.box-stacks-simple {
  .stacks-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 12px;
  }
}

.help-box {
  @include body-text-3;
  color: $ss-gray-100;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 20px;

  &.help-box--green {
    background: $ss-green-5;
    border: 1px solid $ss-green-20;
    border-radius: 8px;
  }
  &.help-box--yellow {
    background: $ss-yellow-5;
    border: 1px solid $ss-yellow-20;
    border-radius: 8px;
  }

  svg {
    flex-shrink: 0;
  }
}

.figure-box {
  display: flex;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  &.nowrap {
    flex-wrap: nowrap;
  }
}

.figure {
  figcaption {
    @include caption-text-2;
    color: $ss-gray-70;
  }
}

.profile-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px;
  background: $ss-white;
  border: 1px solid $ss-gray-30;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  .profile-box__content {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;

    .profile-box__image {
      position: relative;
      width: 78px;
      height: 78px;
      overflow: hidden;
      border-radius: 50%;
      flex-shrink: 0;
    }

    .profile-box__detail {
      .profile-box__name {
        @include h7;
        color: $ss_black;
        margin-bottom: 8px;
      }

      .profile-box__career {
        margin-bottom: 12px;

        p {
          @include body-text-2;
          color: $ss-gray-100;

          + p {
            margin-top: 5px;
          }
        }
      }

      .profile-box__recent_career {
        display: flex;
        align-items: center;
        gap: 7px;

        .profile-box__recent_career_title {
          display: flex;
          gap: 6px;
          align-items: center;
          @include body-text-4-m;
          color: $ss-gray-100;
        }
        .profile-box__recent_career-divider {
          border: 1px solid #DEDEDE;
          height: 14px;
        }
        .profile-box__recent_career_detail {
          @include body-text-2;
          color: $ss_gray-80;
        }
      }
    }

    .profile-box__stack {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 12px;
      gap: 6px 8px;
      @include body-text-3;
      color: $ss-gray-100;
    }
  }

  .profile-link {
    flex-shrink: 0;
  }
}

.all-apply-check-box{
  @include body-text-1;
  color: $ss-gray-100;
  margin: 0 20px 0 8px;
}
.applicant-profile-list-box {
  .applicant-profile-box-wrapper {
    display: flex;
    width: 848px;
    border-top: 1px solid $ss-gray-30;
    padding: 32px 0 22px;

    &:last-child {
      border-bottom: 1px solid $ss-gray-30;
      margin-bottom: 36px;
    }

    .applicant-profile-box {
      position: relative;
      display: flex;
      align-items: flex-start;

      .profile-box__content {
        display: flex;
        gap: 24px;
        cursor: pointer;

        .profile-box__image {
          position: relative;
          width: 78px;
          height: 78px;
          overflow: hidden;
          border-radius: 50%;
          flex-shrink: 0;
        }

        .profile-box__detail {
          width: 526px;


          .profile-box__name {
            @include h7;
            color: $ss_black;
            margin-bottom: 8px;
          }
          .profile-box-new-applicant{
            font-size:10px;
            color: red;
          }

          .profile-box__career {
            margin-bottom: 12px;

            p {
              @include body-text-2;
              color: $ss-gray-100;

              + p {
                margin-top: 5px;
              }
            }
          }

          .profile-box__recent_career {
            display: flex;
            align-items: center;
            gap: 14px;

            .profile-box__recent_career_title {
              display: flex;
              gap: 6px;
              align-items: center;
              @include body-text-4-m;
              color: $ss-gray-100;
              white-space: nowrap;
            }

            .profile-box__recent_career_detail {
              @include body-text-2;
              color: $ss_gray-80;
            }
          }
          .profile-box__application_date{
            @include body-text-3;
            color: $ss-gray-90;
            margin-top: 18px;
          }
        }

        .profile-box__stack {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 12px;
          gap: 6px 8px;
          @include body-text-3;
          color: $ss-gray-100;
        }
      }

      .profile-link {
        display: flex;
        align-items: center;
        gap: 4px;
        text-decoration: none;
        position: absolute;
        padding: 8px 12px 8px 14px;
        border: solid 1px $ss-blue-50;
        border-radius: 4px;
        top: 24px;
        right: 24px;

        span {
          @include btn-text-4;
          color: $ss-blue-50;
        }

        svg {
          width: 8px;
          height: 20px;
          fill: $ss-blue-50;
        }
      }
    }
  }
  .empty-list-tab{
    @include body-text-2;
    color: $ss-gray-100;
    text-align: center;
    padding: 32px 0 40px;
  }
}

.applicant-profile-detail-box {
  .link-wrapper{
      margin-top: 38px;
      height:32px;
      padding: 5px 8px 7px 0px;

    .link-applicant-list{
      @include body-text-2;
        text-decoration: none;
        color: $ss-blue-50;
      padding-left: 5px;
    }
  }
  .applicant-profile-detail {
    display: flex;
    padding: 32px 0px 40px 16px;
    .applicant-profile-box {
      position: relative;
      display: flex;
      align-items: flex-start;

      .profile-box__content {
        display: flex;
        gap: 24px;

        .profile-box__image {
          position: relative;
          width: 78px;
          height: 78px;
          overflow: hidden;
          border-radius: 50%;
          flex-shrink: 0;
        }

        .profile-box__detail {
          width: 749px;
          .profile-box__name {
            @include h7;
            color: $ss_black;
            margin-bottom: 8px;
          }

          .profile-box__career {
            margin-bottom: 12px;

            p {
              @include body-text-2;
              color: $ss-gray-100;

              + p {
                margin-top: 5px;
              }
            }
          }

          .profile-box__recent_career {
            display: flex;
            align-items: center;
            gap: 14px;

            .profile-box__recent_career_title {
              display: flex;
              gap: 6px;
              align-items: center;
              @include body-text-4-m;
              color: $ss-gray-100;
              white-space: nowrap;
            }

            .profile-box__recent_career_detail {
              @include body-text-2;
              color: $ss_gray-80;
            }
          }
          .profile-box__application_date{
            @include body-text-3;
            color: $ss-gray-90;
            margin-top: 18px;
          }
        }

        .profile-box__stack {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 12px;
          gap: 6px 8px;
          @include body-text-3;
          color: $ss-gray-100;
        }

        .profile-box__contact {
          padding: 12px 16px;
          background-color: $ss-green-5;
          border: 1px solid $ss-green-20;
          border-radius: 8px;
          .content-wrapper {
            @extend .flex-box;
            gap: 8px;

            .content {
              @extend .flex-box;
              @include body-text-2;
              gap: 6px;
            }
          }
        }
      }

      .profile-link {
        display: flex;
        align-items: center;
        gap: 4px;
        text-decoration: none;
        position: absolute;
        padding: 8px 12px 8px 14px;
        border: solid 1px $ss-blue-50;
        border-radius: 4px;
        top: 24px;
        right: 24px;

        span {
          @include btn-text-4;
          color: $ss-blue-50;
        }

        svg {
          width: 8px;
          height: 20px;
          fill: $ss-blue-50;
        }
      }
    }
  }
}
.recruiter-inquiry-box {
  padding: 24px;
  background: $ss-white;
  border: 1px solid $ss-gray-30;
  border-radius: 8px;

  .recruiter-inquiry-box__title {
    @include subtitle-text-2;
    color: $ss-gray-100;
  }

  .recruiter-inquiry-box__recruiter, .recruiter-inquiry-box__contact {
    @include body-text-2;
    color: $ss-gray-70;

    strong {
      margin-right: 6px;
      color: $ss-gray-100;
    }
  }

  .recruiter-inquiry-box__contact {
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    background: $ss-gray-30;
    border-radius: 4px;
    cursor: pointer;
  }

  @media (max-width: 1079px) {
    padding: 20px 24px;
  }
}

.reward-employing {
  display: inline-flex;
  align-items: center;
  @include subtitle-text-2;
  color: $ss-blue-50;

  svg {
    margin-right: 8px;
    fill: $ss-blue-50;
  }
}

.toast-box {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 20px;
  border-radius: 8px;
  @include body-text-2;
  color: $ss-gray-100;

  .toast-message {
    white-space: pre-wrap;
  }

  &.success {
    border: solid 1px $ss_green-20;
    background-color: $ss_green-5;

    svg {
      fill: $ss_green-50;
    }
  }

  &.error {
    border: solid 1px $ss_red-20;
    background-color: $ss_red-5;

    svg {
      fill: $ss_red-50;
    }
  }
}

.alert-box {
  @include body-text-3;
  @extend .flex-box;
  gap: 12px;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: $ss_white;
  color: $ss-black;

  svg {
    flex-shrink: 0;
  }

  strong {
    font-weight: 700;
  }

  &.success {
    border: solid 1px $ss_green-20;
  }

  &.warning {
    background-color: $ss-orange-5;
    border: solid 1px $ss-orange-20;
  }
}

.exist-comment{
  margin-bottom: 64px;
}
.comment-box {
  padding: 16px;
  border-bottom: 1px solid $ss-gray-30;

  .comment-info{
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;
    @include body-text-3;
    .comment-user{
      color: $ss-gray-100;
    }
    .text-divide{
      width: 1.5px;
      height: 1.5px;
      background-color:$ss-gray-70;
    }
    .comment-date{
      color: $ss-gray-50;
    }
  }
  .comment-content{
    display: flex;
    align-items: start;
    gap: 8px;
    width: 800px;
    .icon-box{
      @include btn-text-5;
      display: flex;
      width: 61px;
      height: 26px;
      border: 1px solid $ss-gray-40;
      border-radius: 44px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .icon{
        width: 14px;
        height: 14px;
      }
      .positive{
        fill: $ss-green-50;
      }
      .negative{
        fill: $ss-red-50;
      }
    }
    .comment{
      @include content-text-1;
      color: $ss-gray-100;
    }
  }
}

.comment-create-form-box {
  background-color: $ss-gray-10;
  border-radius: 8px;
  padding: 16px 20px;
    margin-top: 16px;

  .comment-create-form {
    @include subtitle-text-1;
    .options-box {
      display: flex;
      gap: 80px;
      margin-bottom: 28px;
      .options {
        @include body-text-1;
        display: flex;
        margin-top: 26px;
        align-items: center;
        gap: 8px;
        .radio-button{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }
  .comment-form-textarea-box{
    @include body-text-4-m;
    .comment-form-textarea{
      @include content-text-1;
      width: 800px;
      height: 112px;
      resize: none;
      margin-bottom: 8px;
      padding: 12px;
    }
    .comment-length{
      @include body-text-3;
      color: $ss-gray-60;
      margin-bottom: 28px;
    }
  }
  .btn-save-comment{
    @include btn-text-2;
    padding:10px 16px 12px 16px;
    background-color: $ss-blue-50;
    color: $ss-white;
    border: none;
    border-radius: 4px;
    -webkit-appearance: none;
    -webkit-border-radius: 4px;
    cursor: pointer;
  }
}
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: -24px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: -24px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: -24px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: -24px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
@-webkit-keyframes m-fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 76px; opacity: 1;}
}

@keyframes m-fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 76px; opacity: 1;}
}

@-webkit-keyframes m-fadeout {
  from {bottom: 76px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes m-fadeout {
  from {bottom: 76px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.floating-alert-box {
  visibility: hidden;
  width: fit-content;
  padding: 12px 24px;
  position: absolute;
  bottom: -24px;
  background: $ss-white;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 3px 2px rgba(0, 0, 0, 0.04);
  @include caption-text-1;

  &.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
}

.empty-selected-stack{
  @include body-text-3;
  width: 100%;
  height: 100%;
  background-color: $ss-gray-10;
  color: $ss-gray-80;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  .empty-selected-stack-arrow{
    fill:$ss-blue-50;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
}

.mobile-empty-selected-stack{
  @include body-text-3;
  padding:16px 24px 0px 24px;
  align-items: center;
  height: 140px;
  justify-content: center;
  text-align: center;
  display: flex;

  .mobile-empty-selected-stack-text{
    width: 100%;
    height: 100%;
    background-color: $ss-gray-10;
    color: $ss-gray-80;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
  }
  &.mobile-empty-selected-stack-range{
    height: 100%;
    .mobile-empty-selected-stack-text{
      background-color: $ss-gray-30;
    }
  }
}

.mobile-selected-stack-chip{
  @include body-text-2;
  display: inline-flex;
  background-color: $ss-blue-10;
  padding: 5px 12px 7px 8px;
  margin: 0 0 12px 12px;
  border-radius: 6px;
}

.stack-img-box {
  margin-right: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.box-stack-culture-summary {
  padding: 16px 24px;
  background-color: $ss-gray-10;
  border-radius: 12px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;

  .summary {
    width: 100%;

    .title-wrapper {
      display: flex;
      justify-content: space-between;

      .title {
        @include btn-text-3;
        color: $ss-gray-100;
      }

    }
  }

  .stack-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: hidden;
    gap: 6px;
  }
}

@media (min-width: 1080px){
  .profile-image-edit-icon{
    display: flex;
    position: absolute;
    top: 60px;
    right: 0px;
    .profile-image-edit{
      border-radius: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
}
}

@media (max-width: 1079px) {
  .profile-box {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;

    .profile-box__content {
      display: flex !important;
      flex-direction: column !important;

      .profile-box__detail {
        .profile-box__name {
          margin-bottom: 10px !important;
        }

        .profile-box__recent_career {
          display: block !important;

          .profile-box__recent_career_title {
            margin-bottom: 8px !important;
          }
          .profile-box__recent_career-divider {
            display: none;
          }
        }
      }
    }
    .profile-link {
      width: 100%;
      justify-content: center;
    }
  }

  .recruiter-inquiry-box {
    position: relative;

    .floating-alert-box {
      position: absolute;
      border: 1px solid $ss-gray-30;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
      z-index: 99;

      &.show {
        -webkit-animation: none;
        animation: none;
      }
    }
  }
  .profile-image-edit-icon {
    display: flex;
    position: absolute;
    top: 60px;
    left: 54px;
    .profile-image-edit {
      border-radius: 10px;
      width: 24px;
      height: 24px;
      cursor: pointer;

    }
  }

  .box-stack-culture-summary {
    padding: 20px;
    flex-direction: column;
    gap: 24px;
  }
}